<template>
  <div>
    <b-card>
      <b-card-header>
        <h2>
          <feather-icon icon="LayersIcon" />
          {{ $t('Routes.addNewBrand') }}
        </h2>
      </b-card-header>
      <b-card-body>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <b-row>
              <b-col cols="12">
                <validation-provider #default="{errors}" :name="$t('AppTI.brand.name')" rules="required|max:50">
                  <b-form-group :label="$t('AppTI.brand.name')" label-for="type">
                    <b-form-input type="text" placeholder="HP" v-model="brandData.nombre" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <validation-provider #default="{ errors }" :name="$t('AppTI.brand.description')"
                rules="required|max:250">
                <b-form-group :label="$t('AppTI.brand.description')" label-for="description">
                  <b-form-textarea id="description" v-model="brandData.descripcion" rows="3"
                    :placeholder="$t('AppTI.brand.description')" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              </b-col>
            </b-row>
            <b-row class="float-right mt-2 mb-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                <b-spinner v-if="addingBrand" small class="mr-1" />
                {{ $t('Lists.Add') }}
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BForm,
  BFormInput,
  BFormTextarea,
  BFormGroup,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required, max,
} from '@validations'
import tiService from '@/services/informationTecnology.service'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref } from '@vue/composition-api'
import router from '@/router'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BFormInvalidFeedback,

    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      required,
      max,
    }
  },
  setup() {
    const brandData = ref({
      tipoMarcaId: 0,
      nombre: '',
      descripcion: '',
      archivado: false,
    })

    const brandBlank = {
      tipoMarcaId: 0,
      nombre: '',
      descripcion: '',
      archivado: false,
    }

    const resetBrandData = () => {
      brandData.value = JSON.parse(JSON.stringify(brandBlank))
    }

    const addingBrand = ref(false)
    const { createBrand } = tiService()

    const {
      refFormObserver,
      resetForm,
      getValidationState,
    } = formValidation(resetBrandData)

    const onSubmit = () => {
      addingBrand.value = true
      createBrand(brandData.value)
        .then(() => {
          addingBrand.value = false
          router.push({ name: 'apps-brands-list' })
        })
    }
    return {
      refFormObserver,
      resetForm,
      getValidationState,
      brandData,
      addingBrand,
      onSubmit,
    }
  },
}
</script>
